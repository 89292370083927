// REACT DEFAULTS
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// LIBs & UTILs
import AppContextProvider from "./context/AppContext";
import { ProductContextProvider } from "./context/ProductContextProvider";
import { OrderProvider } from "./context/OrderContext";

// PAGES & COMPONENTS
import Toast from "./components/common/Toast";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Menu from "./pages/Menu";
import Login from "./pages/AuthPages/Login";
import SignUp from "./pages/AuthPages/SignUp";
import SingleMenu from "./pages/SingleMenu";
import Cart from "./pages/Cart";
import Locations from "./pages/Locations";
import MealPlans from "./pages/MealPlans";
import OrderHistory from "./pages/OrderHistory";
import SingleOrderDetails from "./pages/SingleOrderDetails";
import SingleMealPlan from "./pages/SingleMealPlan";
import Stores from "./pages/Stores";

// STYLES
import "./App.module.scss";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ReturnPolicy from "./pages/ReturnPolicy";
import ContactUs from "./pages/ContactUs";
import ErrorPage from "./pages/ErrorPage";
import SingleBlog from "./pages/SingleBlog";
import Blogs from "./pages/Blogs";

const App = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <ProductContextProvider>
          <OrderProvider>
            <Toast />
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/login" element={<Login />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/menu" element={<Menu />} />
                <Route path="/menu/:slug" element={<SingleMenu />} />
                <Route path="/plans/:slug" element={<MealPlans />} />
                <Route path="/plans/:slug/:slug" element={<SingleMealPlan />} />
                <Route path="/locations" element={<Locations />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/stores/:slug" element={<Stores />} />
                <Route path="/order-history" element={<OrderHistory />} />
                <Route
                  path="/order-history/:reference"
                  element={<SingleOrderDetails />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/return-policy" element={<ReturnPolicy />} />
                <Route path="*" Component={ErrorPage} element={<ErrorPage />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/blogs/:slug" element={<SingleBlog />} />
              </Routes>
            </BrowserRouter>
          </OrderProvider>
        </ProductContextProvider>
      </AppContextProvider>
    </QueryClientProvider>
  );
};
export default App;
