// REACT & DEFAULTS
import {
  useContext,
  ChangeEvent,
  FormEvent,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useMutation, useQuery } from "@tanstack/react-query";

// COMPONENTS
import PlacesAutocomplete from "../PlacesAutocomplete";

// UTILs & LIBs
import { AuthContext } from "../../../context/AppContext";
import { OrderContext } from "../../../context/OrderContext";
import { formatToNigerianNaira } from "../../../lib/utlis/helper";
import { getDeliveryFee } from "../../../lib/handlers/orderHandler";
import { getStores } from "../../../lib/handlers/storeHandler";
import { addToCart } from "../../../lib/handlers/cartHandler";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

// STYLES
import styles from "./CartItemsSummary.module.scss";
import { toast } from "../Toast";

type ExtrasInCart = {
  extra_id: number;
  quantity: number;
  total_amount: number;
};

type ProductsInCart = {
  product_id: number;
  total_amount: number;
  quantity: number;
  extras?: ExtrasInCart[];
};

type PlansInCart = {
  plan_type_id: number;
  plan_duration: number;
  plan_preference: string;
  total_amount: number;
  quantity: number;
  slug: string;
  name: string;
  image: string;
  pdf_url: string;
  delivery_start_date: string;
};

type IOrder = {
  delivery_type: string;
  store_id?: number;
  coupon_code: string;
  delivery_fee?: number;
  delivery_address?: string;
  free_delivery?: boolean;
  recipient?: {
    recipient_name: string;
    recipient_mobile_number: string;
  };
  customer_data?: {
    allergies: string;
    expectant_mum?: string;
    existing_customer: boolean;
    email: string;
    name: string;
    phone_no: string;
  };
};

type ICartData = {
  cart: {
    products: ProductsInCart[];
    plans: PlansInCart[];
  };
  order: IOrder;
};

type PaymentDataProps = {
  authorization_url: string;
  access_code: string;
  reference: string;
};

type PaymentData = {
  status: boolean;
  message: string;
  success?: boolean;
  data: PaymentDataProps;
};

const CartItemsSummary = () => {
  const [cookies] = useCookies<string>(["sofresh-token"]);
  const { user } = useContext(AuthContext);
  const { cartState } = useContext(OrderContext);
  const navigate = useNavigate();

  const [deliveryFeeState, setDeliveryFeeState] = useState(0);
  const [freeDeliveryState, setFreeDeliveryState] = useState(false);

  // GET STORE DATA
  const { data: storesData, isLoading } = useQuery(["stores"], () =>
    getStores(),
  );

  // GET STORE ID
  const getSelectedStore = localStorage.getItem("soFreshStore");
  const selectedStore = getSelectedStore
    ? JSON.parse(getSelectedStore)?.id
    : null;
  const [storeIDState, setStoreIDState] = useState(selectedStore);

  // ORDER RECIEVER's INFORMATION
  const [isOrderingForSomeoneElse, setIsOrderingForSomeoneElse] =
    useState(false);

  const handleSwitchOrderRecipient = (
    event: ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedValue = event.target.value;
    const newValue = selectedValue === "true";
    setIsOrderingForSomeoneElse(newValue);
  };
  // ORDER RECIEVER's INFORMATION

  // TRANSFORM CART DATA
  const transformData = (data: TCartItem[]) => {
    const products: ProductsInCart[] = [];

    data.forEach((item) => {
      const product: any = {
        product_id: item.id,
        total_amount: Number(item.price) * item.quantity,
        quantity: item.quantity,
        extras: [],
      };

      item.extras?.forEach((extra: any) => {
        const extraAmount = Number(extra.price) * extra.quantity;
        const foundExtra = product.extras?.find(
          (e: { extra_id: number }) => e.extra_id === extra.id,
        );

        if (foundExtra) {
          foundExtra.quantity += extra.quantity;
          foundExtra.total_amount += extraAmount;
        } else {
          product.extras?.push({
            extra_id: extra.id,
            quantity: extra.quantity,
            total_amount: extraAmount,
          });
        }
      });

      products.push(product);
    });

    return products;
  };
  const cartProducts = transformData(cartState.products);
  const cartPlans = cartState.plans;

  // FUNCTION TO CONVERT DELIVERY TYPE TO STRING
  const getDeliveryType = (deliveryType: number): string => {
    if (deliveryType === 1) {
      return "pickup";
    } else if (deliveryType === 2) {
      return "delivery";
    } else {
      throw new Error(`${deliveryType} is an invalid delivery type`);
    }
  };

  const [switchTab, setSwitchTab] = useState<number>(2);

  const [orderFormValues, setOrderFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    recipients_name: "",
    recipients_mobile_number: "",
    store_id: "",
    delivery_address: "",
    delievry_fee: 0,
    coupon_code: "",
    allergies: "",
    gender: "",
    expectant_mum: "",
    plan_preference: "",
  });

  // SET USER
  const setUser = useCallback(() => {
    setOrderFormValues((prev) => ({
      ...prev,
      name: user?.name,
      phone: user?.phone_no,
      store_id: selectedStore ? selectedStore : "",
      allergies: user?.allergies ? user?.allergies : "None",
      gender: user?.expectant_mum ? "Female" : "Male",
      expectant_mum: user?.expectant_mum ? user?.expectant_mum : "No",
    }));
  }, [selectedStore, user]);

  useEffect(() => {
    if (user) {
      setUser();
    }
  }, [user, setUser]);
  // END OF SET USER

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    setOrderFormValues({ ...orderFormValues, [name]: value });
  };

  const handleSwitchTabs = (index: number) => {
    setSwitchTab(index);
  };

  const getActiveClass = (index: number, className: string) =>
    switchTab === index ? className : "";

  // FUNCTION TO CALCULATE THE TOTAL PRICE OF A SINGLE PRODUCT & ITs EXTRAS
  const menuPrice = cartState.products.reduce(
    (initialPrice, currentItem) =>
      initialPrice +
      currentItem.quantity *
        (currentItem.discount
          ? Number(currentItem.discount)
          : Number(currentItem.price)) +
      currentItem.extras.reduce(
        (initialExtraPrice, currentExtra) =>
          initialExtraPrice +
          currentExtra.quantity * Number(currentExtra.price),
        0,
      ),
    0,
  );

  const totalPlansAmount = cartState.plans.reduce(
    (sum, item) => sum + item.total_amount,
    0,
  );

  const deliveryDaysArray = cartState?.plans.map(
    (plan: any) => plan?.plan_duration,
  );

  const calculateTotal = (numbers: number[]): number => {
    return numbers.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    );
  };
  const totalDeliveryDays: number = calculateTotal(deliveryDaysArray);

  // LOCATION AUTO-COMPLETE
  const initialLatLng = {
    lat: 0,
    lng: 0,
  };
  const [latLng, setLatLng] = useState(initialLatLng);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 150,
  });

  const handleLocationSelect =
    ({ description }: { description: string }) =>
    () => {
      // NOTE: When user selects a place, we can replace the keyword without
      // requesting data from API by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        if (lat && lng) {
          setLatLng({ lat: lng, lng: lat });
        }
        // console.info('📍LatLng Coordinates: ', latLng)
        // setLatLng(getLatLng(results[0]))
        // console.info('📍 Coordinates: ', { lat, lng })
      });
    };
  const isLocationAvailable =
    latLng.lat === 0 && latLng.lng === 0 ? false : true;
  // GET DELIVERY FEE
  useEffect(() => {
    const fetchDeliveryFee = async () => {
      const data = await getDeliveryFee(latLng);
      if (data && isLocationAvailable) {
        setDeliveryFeeState(await data.price);
        setStoreIDState(await data.store_id);
        setFreeDeliveryState(await data.free_delivery);
      }
    };
    fetchDeliveryFee();
  }, [isLocationAvailable, latLng]);
  // END OF LOCATION AUTO-COMPLETE

  const totalDeliveryFee =
    cartState?.plans?.length > 0
      ? totalDeliveryDays * deliveryFeeState
      : deliveryFeeState;

  const deliveryFee =
    switchTab === 1 || freeDeliveryState ? 0 : deliveryFeeState;

  useEffect(() => {
    setOrderFormValues((prev) => ({
      ...prev,
      delivery_type: getDeliveryType(Number(switchTab)),
    }));
  }, [deliveryFee, switchTab]);

  const totalPrice =
    Number(menuPrice) + Number(totalDeliveryFee) + Number(totalPlansAmount);

  // ORDER DETAILS
  const orderData: ICartData = {
    cart: {
      products: cartProducts,
      plans: cartPlans,
    },
    order: {
      store_id:
        switchTab === 1 ? Number(orderFormValues.store_id) : storeIDState,
      coupon_code: orderFormValues.coupon_code
        ? orderFormValues.coupon_code
        : "",
      delivery_type: getDeliveryType(switchTab),
      delivery_address: value,
      delivery_fee: freeDeliveryState ? 0 : totalDeliveryFee,
      // delivery_fee: freeDeliveryState ? 0 : deliveryFee,
      free_delivery: freeDeliveryState,
      recipient: {
        recipient_name: orderFormValues.recipients_name,
        recipient_mobile_number: orderFormValues.recipients_mobile_number,
      },
      customer_data: {
        allergies: orderFormValues.allergies,
        expectant_mum: orderFormValues.expectant_mum,
        existing_customer: user?.name ? true : false,
        name: orderFormValues.name,
        email: orderFormValues.email,
        phone_no: orderFormValues.phone,
      },
    },
  };

  // HANDLE MUTATAE FORM DATA
  const { mutate: addToCartMutation } = useMutation(
    () =>
      addToCart(
        { token: cookies["sofresh-token"] },
        {
          ...orderData,
        },
      ),
    {
      onSuccess: async (res: PaymentData) => {
        const { data, status } = res;

        if (data && status === true) {
          // toast.info('Payment Initiated!')
          window.location.href = data?.authorization_url;
          localStorage.removeItem("cart");
        }
      },
      onError: (e: Error) => {
        toast.error(
          `${
            e.message === "Validation Error"
              ? "All fields are required, Please fill the empty fields!"
              : "An error occured, Please try again!"
          } `,
        );
        console.error({ e });
      },
    },
  );

  // HANDLE CHECKOUT/PAYMENT LOGIC
  const handlePayment = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // CHECK IF USER IS LOGGED IN BEFORE PAYMENT
    // if (isAuthenticated) {
    if (switchTab === 1) {
      addToCartMutation();
    } else if (switchTab === 2) {
      addToCartMutation();
    } else {
      navigate("/cart");
      throw new Error("Delievry type is invalid");
    }
    // } else {
    //   navigate("/login?location=cart");
    // }
  };

  return (
    <article className={styles.cartItemsSummary}>
      <h2 className={styles.cartItemsHeading}>Checkout</h2>

      <form className={styles.cartForm} onSubmit={handlePayment}>
        <div className={styles.paymentFormGroup}>
          <label htmlFor="name" className={styles.paymentFormLabel}>
            Full Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Enter full name"
            className={styles.paymentFormInput}
            onChange={handleChange}
            value={orderFormValues.name}
            required
          />
        </div>

        <div className={styles.paymentFormGroup}>
          <label htmlFor="email" className={styles.paymentFormLabel}>
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter full email"
            className={styles.paymentFormInput}
            onChange={handleChange}
            value={orderFormValues.email}
            required
          />
        </div>

        <div className={styles.paymentFormGroup}>
          <label htmlFor="phoneNumber" className={styles.paymentFormLabel}>
            Mobile Number
          </label>
          <input
            type="text"
            name="phone"
            id="phoneNumber"
            placeholder="Enter mobile number"
            className={styles.paymentFormInput}
            onChange={handleChange}
            value={orderFormValues.phone}
            inputMode="numeric"
            pattern="[0-9]{11}"
            required
          />
        </div>

        <div className={`${styles.paymentFormGroup}`}>
          <label htmlFor="reciever" className={styles.paymentFormLabel}>
            Are you ordering for someone else?
          </label>
          <select
            name="reciever"
            id="reciever"
            className={`${styles.paymentFormInput} ${styles.paymentFormSelectOption}`}
            onChange={handleSwitchOrderRecipient}
            value={isOrderingForSomeoneElse ? "true" : "false"}
            required
          >
            <option value={"true"} className={styles.paymentFormSelectOption}>
              Yes
            </option>
            <option value={"false"} className={styles.paymentFormSelectOption}>
              No
            </option>
          </select>
        </div>

        {isOrderingForSomeoneElse && (
          <>
            <div className={styles.paymentFormGroup}>
              <label
                htmlFor="recipients_name"
                className={styles.paymentFormLabel}
              >
                Receiver's Name
              </label>
              <input
                type="text"
                name="recipients_name"
                id="recipients_name"
                placeholder={`Enter receiver's full name`}
                className={styles.paymentFormInput}
                onChange={handleChange}
                value={orderFormValues.recipients_name}
                required
              />
            </div>

            <div className={styles.paymentFormGroup}>
              <label
                htmlFor="recipients_mobile_number"
                className={styles.paymentFormLabel}
              >
                Receiver's Mobile Number
              </label>
              <input
                type="text"
                name="recipients_mobile_number"
                id="recipients_mobile_number"
                placeholder={`Enter receiver's mobile number`}
                className={styles.paymentFormInput}
                onChange={handleChange}
                value={orderFormValues.recipients_mobile_number}
                inputMode="numeric"
                pattern="[0-9]{11}"
                required
              />
            </div>
          </>
        )}

        <div className={styles.formTabsSwitch}>
          <div className={styles.formTabsSwitchBtnContainer}>
            <button
              type="button"
              onClick={() => handleSwitchTabs(1)}
              className={`${styles.formTabsSwitchBtn} ${getActiveClass(
                1,
                styles.active,
              )}`}
            >
              Pickup Order
            </button>
            <button
              type="button"
              onClick={() => handleSwitchTabs(2)}
              className={`${styles.formTabsSwitchBtn} ${getActiveClass(
                2,
                styles.active,
              )}`}
            >
              Delivery
            </button>
          </div>

          {switchTab === 1 ? (
            <div
              className={`${styles.pickUpOrder} ${
                switchTab === 1 && styles.activeTab
              } 
            `}
            >
              {!isLoading && (
                <div className={styles.paymentFormGroup}>
                  <label className={styles.paymentFormLabel} htmlFor="store">
                    Store
                  </label>
                  <select
                    name="store_id"
                    id="store"
                    className={`${styles.paymentFormInput} ${styles.paymentFormSelectOption}`}
                    // onChange={handleChange}
                    value={orderFormValues.store_id}
                    disabled={selectedStore}
                    required
                  >
                    <option value="">
                      Which store would you like to pick up from?
                    </option>
                    {storesData &&
                      storesData.map((store: Store) => (
                        <option
                          key={store.id}
                          value={store.id}
                          className={styles.paymentFormSelectOption}
                        >
                          {store.name}
                        </option>
                      ))}
                  </select>
                </div>
              )}
            </div>
          ) : (
            <div
              className={`${styles.delivery} ${
                switchTab === 2 && styles.activeTab
              }`}
            >
              <div className={styles.paymentFormGroup}>
                <PlacesAutocomplete
                  handleLocationSelect={handleLocationSelect}
                  ready={ready}
                  value={value}
                  status={status}
                  data={data}
                  setValue={setValue}
                  clearSuggestions={clearSuggestions}
                />
              </div>

              <div className={styles.paymentFormGroup}>
                <p className={styles.paymentFormLabel}>Delivery Cost</p>
                <p className={styles.deliveryFee}>
                  {formatToNigerianNaira(deliveryFee)}
                </p>
              </div>
            </div>
          )}
        </div>

        <div className={styles.paymentFormGroup}>
          <label htmlFor="promoCode" className={styles.paymentFormLabel}>
            Add Promo Code
          </label>
          <input
            type="text"
            name="promoCode"
            id="promoCode"
            placeholder="Enter promo code"
            className={styles.paymentFormInput}
            onChange={handleChange}
            value={orderFormValues.coupon_code}
            disabled
          />
        </div>

        {cartState.plans.length > 0 && (
          <>
            <div className={styles.paymentFormGroup}>
              <label htmlFor="allergies" className={styles.paymentFormLabel}>
                Allergies or nutrition health concerns
              </label>
              <input
                type="text"
                name="allergies"
                id="allergies"
                placeholder={`Do you have any allergies?, we'll like to know`}
                className={styles.paymentFormInput}
                onChange={handleChange}
                value={orderFormValues.allergies}
              />
            </div>

            <div className={styles.paymentFormGroup}>
              <label htmlFor="gender" className={styles.paymentFormLabel}>
                Gender
              </label>
              <select
                className={styles.paymentFormSelect}
                name="gender"
                id="gender"
                value={orderFormValues.gender}
                onChange={handleChange}
              >
                <option className={styles.paymentFormOption} value="male">
                  Male
                </option>
                <option className={styles.paymentFormOption} value="female">
                  Female
                </option>
              </select>
            </div>

            {orderFormValues.gender === "female" && (
              <div className={styles.paymentFormGroup}>
                <label
                  htmlFor="expectant_mum"
                  className={styles.paymentFormLabel}
                >
                  Are you an expectant mum
                </label>
                <input
                  type="text"
                  name="expectant_mum"
                  id="expectant_mum"
                  placeholder={`Kindly state your  menu preferences if any`}
                  className={styles.paymentFormInput}
                  onChange={handleChange}
                  value={orderFormValues.expectant_mum}
                />
              </div>
            )}
          </>
        )}

        <div className={styles.cartSummary}>
          <div className={styles.cartMenuCost}>
            <p className={styles.menuCostTitle}>Total Meal Cost</p>
            <p className={styles.menuCostPrice}>
              {formatToNigerianNaira(menuPrice)}
            </p>
          </div>

          {cartState.plans.length > 0 && (
            <div className={styles.cartMenuCost}>
              <p className={styles.menuCostTitle}>Total Plans Cost</p>
              <p className={styles.menuCostPrice}>
                {formatToNigerianNaira(totalPlansAmount)}
              </p>
            </div>
          )}

          <div className={styles.cartDeliveryFee}>
            <p className={styles.deliveryFeeTitle}>Delivery Fee</p>
            <p
              className={`${styles.deliveryFeeCost} ${
                cartState?.plans?.length > 0 &&
                totalDeliveryDays > 0 &&
                "!text-[#041d0570]"
              }`}
            >
              {freeDeliveryState
                ? "Free Delivery"
                : formatToNigerianNaira(deliveryFeeState)}
            </p>
          </div>

          {cartState?.plans?.length > 0 && (
            <div className={styles.cartDeliveryFee}>
              <p className={styles.deliveryFeeTitle}>
                Plan Duration {`(${totalDeliveryDays})`} x Delivery Fee
              </p>
              <p className={styles.deliveryFeeCost}>
                {freeDeliveryState
                  ? "Free Delivery"
                  : formatToNigerianNaira(totalDeliveryFee)}
              </p>
            </div>
          )}

          <div className={styles.cartTotalCost}>
            <p className={styles.totalCostTitle}>Total Cost</p>
            <p className={styles.totalCostPrice}>
              {formatToNigerianNaira(totalPrice)}
            </p>
          </div>

          <div className={styles.cartBtnContainer}>
            <button className={styles.cartBtn} type="submit">
              Proceed to Payment
            </button>
          </div>
        </div>
      </form>
    </article>
  );
};

export default CartItemsSummary;
