// REACT DEFAULTS
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

// LIBs & UTILs
import { sortFn } from "../../lib/utlis/helper";
import {
  getProductCategoriesInStore,
  getProductsInStore,
  getSingleStore,
} from "../../lib/handlers/storeHandler";

// COMPONENTS & UTILS
import Layout from "../../components/layouts/Layout";
import Card from "../../components/common/Card";
import { Oval } from "react-loader-spinner";

// STYLES
import styles from "./Stores.module.scss";

const Stores = () => {
  let { slug } = useParams();

  const [selectedCategory, setSelectedCategory] = useState("all");

  const { data: storeDetails, isLoading: isStoreDetailsLoading } = useQuery(
    ["single-store"],
    () => getSingleStore({ slug }),
  );

  const {
    data: inStoreProductCategoriesData,
    isLoading: isInStoreProductCategoriesDataLoading,
  } = useQuery([`inStore-categories-${slug}`], () =>
    getProductCategoriesInStore({ slug }),
  );

  const { data: inStoreProductsData, isLoading: isInStoreProductsDataLoading } =
    useQuery([`inStore-products`], () => getProductsInStore({ slug }));

  const getProductsByCategory = (category: string): TProductType[] => {
    if (category === "all") {
      return inStoreProductsData;
    } else {
      return (
        inStoreProductsData &&
        (inStoreProductsData
          ?.filter(
            (product: any) =>
              product?.categories &&
              product?.categories?.find(
                (item: ICategory) => item?.name === category,
              ),
          )
          .map((product: any) => {
            if (!product) return null; // Added a check to handle undefined values

            return {
              ...product,
            };
          })
          .filter(Boolean) as TProductType[])
      );
    }
  };

  const handleCategoryFilter = (category: string) => {
    setSelectedCategory(category);
  };

  return (
    <Layout title={" Stores "} description={"Stores Page"}>
      <main className={styles.stores}>
        {isStoreDetailsLoading ? (
          <div className="w-full flex items-center justify-center my-32">
            <Oval wrapperClass="ml-2" height={50} width={50} strokeWidth={5} />
          </div>
        ) : (
          <>
            <header
              className={`container h-[279px] mb-20 ${styles.storesHeader}`}
            >
              <div
                className="h-full w-full rounded-[10px] flex items-end justify-center bg-cover bg-no-repeat"
                style={{
                  width: "100%",
                  background: `linear-gradient(0deg, rgba(5, 21, 3, 0.85) 0%, rgba(0, 0, 0, 0.00) 100%), url(${storeDetails?.image}), lightgray 0px -152.5px / 100% 352.688%`,
                }}
              >
                <h1 className="font-semibold font-BRFirma text-white text-4xl pb-10">
                  {storeDetails?.store_name}
                </h1>
              </div>
            </header>

            <div className={`container ${styles.categoriesContainer}`}>
              {isInStoreProductCategoriesDataLoading ? (
                <div className={`{styles.loader}  my-32`}>
                  <Oval
                    wrapperClass="ml-2"
                    height={30}
                    width={30}
                    strokeWidth={5}
                  />
                </div>
              ) : (
                <div className={styles.productCategories}>
                  <button
                    onClick={() => handleCategoryFilter("all")}
                    className={`${styles.category}  ${
                      selectedCategory === "all"
                        ? styles.categoryActive
                        : styles.categoryInActive
                    }`}
                  >
                    All Categories
                  </button>
                  {inStoreProductCategoriesData?.sort(sortFn)?.map(
                    (item: any) =>
                      item?.category && (
                        <button
                          key={item?.category?.id}
                          onClick={() =>
                            handleCategoryFilter(item?.category?.name)
                          }
                          className={`${styles.category} ${
                            selectedCategory === item?.category?.name
                              ? styles.categoryActive
                              : styles.categoryInActive
                          }`}
                        >
                          {item?.category?.name}
                        </button>
                      ),
                  )}
                </div>
              )}
            </div>

            <section className={`container ${styles.storesContainer}`}>
              {isInStoreProductsDataLoading ? (
                <div className={`{styles.loader}  my-32`}>
                  <Oval
                    wrapperClass="ml-2"
                    height={50}
                    width={50}
                    strokeWidth={5}
                  />
                </div>
              ) : (
                <>
                  {getProductsByCategory(selectedCategory)?.length < 1 ? (
                    <p className="w-full text-center px-[16px]">
                      NO PRODUCT IN THIS CATEGORY, PLEASE CHECK OTHER
                      CATEGORIES.
                    </p>
                  ) : (
                    <div className={styles.storesCards}>
                      {/* The util sortFn() is used to sort products/stores in ORDER OF LATEST */}
                      {getProductsByCategory(selectedCategory)
                        ?.sort(sortFn)
                        ?.map(
                          (product: TProductType) =>
                            product && (
                              <Card
                                key={product?.slug}
                                maxWidth={"100%"}
                                width={"300px"}
                                bigScreensImageUrl={product?.image}
                                smallScreensImageUrl={product?.image}
                                cardTitle={product?.name}
                                cardDescription={product?.description}
                                cardCaption={product?.price}
                                discount={product?.discount}
                                itemLink={`/menu/${product?.slug}`}
                                btnText={"Select"}
                              />
                            ),
                        )}
                    </div>
                  )}
                </>
              )}
            </section>
          </>
        )}
      </main>
    </Layout>
  );
};

export default Stores;
