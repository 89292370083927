import BreadCrumb from "../../components/layouts/Contact/BreadCrumb";
import FormContact from "../../components/layouts/Contact/Form";
import Hotlines from "../../components/layouts/Contact/Hotlines";
// import SofreshOutlets from "../../components/layouts/Contact/SofreshOutlets";
import Layout from "../../components/layouts/Layout";

const ContactUs = () => {
  return (
    <Layout title={" Contact "} description={"Contact Page"}>
      <main>
        <BreadCrumb />
        <FormContact />
        <Hotlines />
        {/* <SofreshOutlets /> */}
      </main>
    </Layout>
  );
};

export default ContactUs;
