// REACT DEFAULTS
import { Link } from "react-router-dom";

// STYLES
import styles from "./Button.module.scss";

interface IButton {
  linkHref?: string;
  onClick?: () => void;
  lightBtn?: boolean;
  greenBtn?: boolean; // Add this line
  linkText: string;
  full?: boolean;
  target?: boolean;
  download?: string;
  smallPaddingInline?: boolean;
  className?: string;
}

const Button = ({
  linkHref,
  onClick,
  lightBtn,
  greenBtn, // Add this line
  linkText,
  full,
  target,
  download,
  smallPaddingInline,
  className,
}: IButton) => {
  return (
    <>
      {onClick && !linkHref ? (
        <button
          onClick={onClick}
          className={`${styles.btn} ${
            lightBtn ? styles.lightBtn : greenBtn ? styles.greenBtn : styles.solidBtn // Update this line
          } 
    ${full ? styles.fullWidth : ""} ${className} ${
      smallPaddingInline ? styles.smallPaddingInline : ""
    }`}
        >
          {linkText}
        </button>
      ) : (
        <Link
          to={linkHref || "/"}
          onClick={onClick}
          className={`${styles.btn} ${
            lightBtn ? styles.lightBtn : greenBtn ? styles.greenBtn : styles.solidBtn // Update this line
          }
       ${full ? styles.fullWidth : ""} ${
         smallPaddingInline ? styles.smallPaddingInline : ""
       }`}
          download={download}
          target={download || target ? "_blank" : ""}
          rel={download || target ? "noopener noreferrer" : ""}
        >
          {linkText}
        </Link>
      )}
    </>
  );
};

export default Button;
