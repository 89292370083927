// REACT DEFAULTS
import { useEffect, useState, useContext, useCallback } from "react";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

// COMPONENTS, LIBs & UTILs
import Button from "../../common/Button";
import { AuthContext } from "../../../context/AppContext";
import { OrderContext } from "../../../context/OrderContext";
// import { getStores } from '../../../lib/handlers/storeHandler'
import { getPlans } from "../../../lib/handlers/productHandler";
import { scrollToTop, sortFn } from "../../../lib/utlis/helper";

// IMAGES & ICONS
import Logo from "../../../assests/images/sofreshLogo__.png";
// import Logo from '../../../assests/images/sofreshLogo.png'
import userAvatar from "../../../assests/images/userAvatar.jpg";
import { VscMenu, VscClose } from "react-icons/vsc";
import { FiSearch, FiChevronDown } from "react-icons/fi";
import { MdShoppingCart } from "react-icons/md";

// STYLES
import styles from "./NavBar.module.scss";
import Modal from "../../common/Modal";

interface NavigationData {
  id: number;
  title: string;
  url: string;
  is_active: string;
  created_at: string;
  updated_at: string;
}

const apiUrl =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_PROD
    : process.env.REACT_APP_DEV;

const Navbar = () => {
  const { user, handleAuthState } = useContext(AuthContext);
  const { cartState } = useContext(OrderContext);
  // const { cartState, dispatch } = useContext(OrderContext)
  const location = useLocation();
  const navigate = useNavigate();

  const [isFulfilmentModalOpen, setIsFulfilmentModalOpen] = useState(false);
  const handleOpenFulfilmentModal = () => {
    setIsFulfilmentModalOpen((prev) => !prev);
  };
  const [plansDropDownActive, setPlansDropDownActive] = useState(false);
  const [dropDownActive, setDropDownActive] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [windowPosition, setWindowPosition] = useState(0);

  // GET ALL PLANS NAME
  const { data: allPlans, isLoading: isAllPlansLoading } = useQuery<
    TMealPlansData[]
  >(["meal-plans"], () => getPlans());

  const toggleDropDownMenu = () => {
    setDropDownActive((prevState) => !prevState);
  };

  const handlePlansMenuToggle = () => {
    setPlansDropDownActive((prevState) => !prevState);
  };

  const toggleNav = () => {
    setIsNavOpen((prevState) => !prevState);
  };

  // USEEFFECT TO FREE BACKGROUND SCROLLING WHEN NAV IS OPEN ON MOBILE
  useEffect(() => {
    if (isNavOpen && isScrolled) {
      window.document.body.style.overflowY = "hidden";
    } else {
      window.document.body.style.overflowY = "scroll";
    }
  }, [isNavOpen, isScrolled, windowPosition]);

  useEffect(() => {
    window.onscroll = () => {
      setWindowPosition(window.scrollY);
    };

    if (windowPosition >= 45) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }, [windowPosition]);

  const handleLogOut = useCallback(() => {
    handleAuthState(null);
    navigate("/");
  }, [handleAuthState, navigate]);

  const totalItemsInCart =
    (cartState?.products && cartState?.products?.length > 0
      ? cartState?.products?.length
      : 0) +
    (cartState?.plans && cartState?.plans?.length > 0
      ? cartState?.plans?.length
      : 0);

  const [navigationData, setNavigationData] = useState<NavigationData | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const requestOptions: RequestInit = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${apiUrl}/public/navigations`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setNavigationData(result[0]);
      })
      .catch((error) => setError(error.toString()));
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <>
      <nav
        className={`md:px-6 lg:px-0 ${styles.navbarContainer} ${
          isScrolled ? styles.invertNavBar : ""
        } ${isNavOpen ? styles.navSolidBg : styles.invertNavBar}`}
      >
        <div className={`lg:container relative ${styles.navbar}`}>
          <div className={`px-4 lg:px-0 ${styles.navBrand}`}>
            <Link
              to={"/"}
              className={styles.navLogoContainer}
              onClick={() => scrollToTop()}
            >
              <img
                className={`${styles.navLogo} xl:w-[82px] xl:h-[82px]`}
                src={Logo}
                alt="logo"
                loading="eager"
              />
            </Link>

            <button className={styles.hamburger} onClick={toggleNav}>
              {isNavOpen ? <VscClose /> : <VscMenu />}
            </button>
          </div>

          <div
            className={`${styles.navItems} ${
              isNavOpen ? styles.navOpen : styles.navClosed
            }`}
          >
            <ul className={styles.navItemsLeft}>
              <li className={styles.navLink}>
                <NavLink
                  onClick={() => scrollToTop()}
                  to={"/menu"}
                  className={({ isActive }) =>
                    isActive ? styles.activeNavLink : styles.inActiveNavLink
                  }
                >
                  Our Menu
                </NavLink>
              </li>

              {user && (
                <li className={styles.navLink}>
                  <NavLink
                    onClick={() => scrollToTop()}
                    to={"/order-history"}
                    className={({ isActive }) =>
                      isActive ? styles.activeNavLink : styles.inActiveNavLink
                    }
                  >
                    Orders
                  </NavLink>
                </li>
              )}

              {allPlans && allPlans.length > 0 && (
                <li className={styles.navLink}>
                  <button
                    className={
                      location.pathname.includes("/plans")
                        ? styles.activeNavLink
                        : styles.inActiveNavLink
                    }
                    onClick={handlePlansMenuToggle}
                  >
                    <span className="pe-2"> All Plans</span>
                    <FiChevronDown
                      className={`${
                        plansDropDownActive
                          ? styles.plansDropDownBtnActive
                          : styles.plansDropDownBtnInactive
                      } ${styles.plansMenuBtn}`}
                    />
                    <ul
                      className={`${
                        plansDropDownActive
                          ? styles.plansDropDownMenuActive
                          : styles.displayNone
                      } ${styles.plansDropDownMenuLinks}`}
                    >
                      {plansDropDownActive &&
                        !isAllPlansLoading &&
                        allPlans?.sort(sortFn).map((plan) => (
                          <li
                            key={plan.slug}
                            className={`${styles.dropDownMenuItem} ${
                              location.pathname.includes(plan.slug)
                                ? styles.activePlanType
                                : ""
                            }`}
                          >
                            <Link
                              className={styles.dropDownMenuLink}
                              to={`/plans/${plan.slug}`}
                              onClick={() => setIsNavOpen(false)}
                              key={plan.slug}
                            >
                              {plan.name}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </button>
                </li>
              )}

              <li className={styles.navLink}>
                <NavLink
                  to={"/locations"}
                  className={({ isActive }) =>
                    isActive ? styles.activeNavLink : styles.inActiveNavLink
                  }
                >
                  Find Us
                </NavLink>
              </li>
              {navigationData && (
                <li className={styles.navLinkBtn}>
                  <Button
                    linkHref={navigationData.url}
                    linkText={navigationData.title}
                  />
                </li>
              )}
            </ul>
            <ul className={styles.navItemsRight}>
              <li className={styles.navLinkIcon}>
                <FiSearch />
              </li>

              {totalItemsInCart > 0 && (
                <li className={`${styles.navLinkIcon} ${styles.navCartIcon}`}>
                  <Link
                    onClick={() => scrollToTop()}
                    to={totalItemsInCart > 0 ? "/cart" : "/menu"}
                  >
                    <MdShoppingCart />
                    {cartState?.products?.length > 0 ||
                    cartState?.plans?.length > 0 ? (
                      <span className={styles.navCartBadge}>
                        {totalItemsInCart}
                      </span>
                    ) : (
                      ""
                    )}
                  </Link>
                </li>
              )}

              {user ? (
                <figure className={styles.userAvatarContainer}>
                  <img
                    className={styles.userAvatar}
                    src={userAvatar}
                    alt={`${user.name}'s avatar`}
                  />

                  <figcaption className={styles.userDetails}>
                    <p className={styles.userName}>
                      <span className={styles.userNameGreet}>Hello, </span>
                      {user.name}
                    </p>
                    <button
                      onClick={toggleDropDownMenu}
                      className={styles.dropDownMenu}
                    >
                      <FiChevronDown
                        className={`${
                          dropDownActive
                            ? styles.dropDownBtnActive
                            : styles.dropDownBtnInactive
                        }`}
                      />
                    </button>
                  </figcaption>

                  <ul
                    className={`${
                      dropDownActive
                        ? styles.dropDownMenuActive
                        : styles.displayNone
                    } ${styles.dropDownMenuLinks}`}
                  >
                    <li className={styles.dropDownMenuItem}>
                      <Link
                        className={styles.dropDownMenuLink}
                        to={"/"}
                        onClick={toggleNav}
                      >
                        View Profile
                      </Link>
                    </li>
                    <li className={styles.dropDownMenuItem}>
                      <Link
                        className={styles.dropDownMenuLink}
                        to={"/order-history"}
                        onClick={toggleNav}
                      >
                        Order History
                      </Link>
                    </li>
                    <li className={styles.dropDownMenuItem}>
                      <button
                        className={styles.dropDownMenuLink}
                        onClick={handleLogOut}
                      >
                        Log Out
                      </button>
                    </li>
                  </ul>
                </figure>
              ) : (
                <>
                  {/* <li className={styles.navLinkBtn}>
                  <Button linkHref='/login' linkText={'Sign In'} lightBtn />
                </li> */}

                  <li className={styles.navLinkBtn}>
                    <Button
                      // linkHref='https://chowdeck.com/store/tag/so-fresh?page=tags'
                      linkText="Order Now"
                      onClick={handleOpenFulfilmentModal}
                    />
                    {/* <Button
                    linkHref={`https://chowdeck.com/store/tag/so-fresh?page=tags`}
                    linkText={'Order Now'}
                    // onClick={
                    //   () =>
                    //     navigate(
                    //       'https://chowdeck.com/store/tag/so-fresh?page=tags',
                    //       { replace: true }
                    //     )
                    //   // selectedStore ? handleNavigateToStore : handleSelectStore
                    // }
                  /> */}
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>

      {isFulfilmentModalOpen && (
        <Modal
          isOpen={isFulfilmentModalOpen}
          onClose={() => setIsFulfilmentModalOpen(false)}
          width={`max-w-[450px]`}
          displayCloseIcon
        >
          <div className="w-full flex flex-col items-center justify-center gap-8">
            <h4 className="text-lg">Select Delivery Type</h4>

            <div className="w-full flex items-center justify-center gap-8">
              <Button
                linkHref="https://chowdeck.com/store/tag/so-fresh?page=tags"
                linkText="Chowdeck"
                greenBtn
              />

              <Button
                linkHref="https://glovo.go.link/open?link_type=store&brand_id=8453&adjust_t=vj6hcng&adjust_campaign=Web_To_App&adjust_adgroup=Referral_Acquisition_PartnerWeb_All_PartnersVisibility_Eng_Pm01_NG_AllCities_LocalBudget_09072024&adjust_deeplink=glovoapp%3A%2F%2Fopen%3Flink_type%3Dstore%26brand_id%3D8453&adjust_fallback=https%3A%2F%2Fglovoapp.com%2F%3Futm_source%3DWeb_to_app_banner%26utm_medium%3DWeb_To_App%26utm_campaign%3DReferral_Acquisition_PartnerWeb_All_PartnersVisibility_Eng_Pm01_NG_AllCities_LocalBudget_09072024&adj_redirect_macos=https%3A%2F%2Fglovoapp.com%2F%3Futm_source%3DWeb_to_app_banner%26utm_medium%3DWeb_To_App%26utm_campaign%3DReferral_Acquisition_PartnerWeb_All_PartnersVisibility_Eng_Pm01_NG_AllCities_LocalBudget_09072024"
                linkText="Glovo"
              />

              <Button
                linkHref="https://api.whatsapp.com/send/?phone=2348062868430&text&type=phone_number&app_absent=0"
                linkText="Pick Up"
                lightBtn
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Navbar;
