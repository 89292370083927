import Layout from "../../components/layouts/Layout";
import Button from "../../components/common/Button";
import { getCategoriesWithBlogs } from "../../lib/handlers/blogs";
import { useQuery } from "@tanstack/react-query";
import { Oval } from "react-loader-spinner";

const Blogs = () => {
  const {
    data: categoriesWithBlogsData,
    isLoading: iscategoriesWithBlogsDataLoading,
  } = useQuery(["categories-with-blogs"], () => getCategoriesWithBlogs());

  return (
    <Layout title="Blogs" description="Blogs Page">
      <main className="container">
        {iscategoriesWithBlogsDataLoading ? (
          <div className="w-full flex items-center justify-center my-32">
            <Oval wrapperClass="ml-2" height={50} width={50} strokeWidth={5} />
          </div>
        ) : categoriesWithBlogsData && categoriesWithBlogsData.length < 0 ? (
          categoriesWithBlogsData.map(
            (blogCategory) =>
              blogCategory.blogs.length > 0 && (
                <section className="px-4 py-10" key={blogCategory.title}>
                  <h1 className="text-[#041D05] text-[30px] font-semibold leading-[1.1] text-center lg:text-start">
                    {blogCategory.title}
                  </h1>

                  <div className="w-full flex flex-col md:flex-row md:flex-wrap items-center justify-center gap-8 lg:gap-0 lg:justify-between py-10">
                    {blogCategory.blogs.map((blog: any) => (
                      <figure
                        className="w-[270px] flex flex-col items-center justify-center hover:shadow-blog-card rounded-lg p-4"
                        key={blog.id}
                      >
                        <div className="w-full aspect-square flex group/blogImage overflow-hidden">
                          <img
                            className="group-hover/blogImage:scale-[1.1] transition-all duration-300 ease-in"
                            src={blog.images[0]}
                            alt={blog.title}
                          />
                        </div>

                        <figcaption className="w-full flex flex-col gap-3 pt-4">
                          <span className="w-fit bg-[#F4E4D7] text-[#A17759] text-xs font-semibold leading-[1.4] rounded-[4px] px-[10px] py-[5px]">
                            {blogCategory.title}
                          </span>
                          <h2 className="text-[#374935] text-[20px] font-semibold leading-[1.4] line-clamp-2 text-ellipsis">
                            {blog.title}
                          </h2>
                          <p className="text-[#041D05] text-[12px] font-normal leading-[1.4] pb-4">
                            Published On:
                            <span className="text-[#339E35] ps-1">
                              {blog.created_at}
                            </span>
                          </p>

                          <Button
                            linkText="Read Blog"
                            linkHref={`/blogs/${blog.slug}`}
                          />
                        </figcaption>
                      </figure>
                    ))}
                  </div>
                </section>
              ),
          )
        ) : (
          <div className="w-full min-h-[60dvh] flex items-center justify-center">
            <h1 className="text-5xl text-[#041d05] font-semibold">
              No Blogs Yet
            </h1>
          </div>
        )}
      </main>
    </Layout>
  );
};

export default Blogs;
