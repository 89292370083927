// REACT & DEFAULTS
import { useState, useEffect, useCallback, ReactNode, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

// LIBs & UTILs
import { getSingleProducts } from "../../lib/handlers/productHandler";
import { formatToNigerianNaira } from "../../lib/utlis/helper";

// COMPONENTS, IMAGES & ICONS
import Layout from "../../components/layouts/Layout";
import OrderPlusExtraModal from "../../components/common/OrderPlusExtraModal";
import { Oval } from "react-loader-spinner";
import { toast } from "../../components/common/Toast";
import { TiStarFullOutline, TiStarOutline } from "react-icons/ti";

// STYLES
import styles from "./SingleMenu.module.scss";

type Reviews = {
  id: string | number;
  user: string;
  comment: string;
  rating: number;
  is_active: boolean;
};

type Extras = {
  id: number;
  image: Array<string>;
  is_active: boolean;
  name: string;
  price: number;
  slug: string;
};

type Categories = {
  id: number;
  name: string;
  description: string;
  image: string;
  price: number;
  slug: string;
  is_active: boolean;
};

type SingleProductData = {
  id: number;
  name: string;
  description: string;
  image: Array<string>;
  slug: string;
  categories: Array<Categories>;
  extras: Array<Extras>;
  price: number;
  discount: number;
  reviews: Array<Reviews>;
  is_active: boolean;
};

const SingleMenu = () => {
  const navigate = useNavigate();
  let { slug } = useParams();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  // GET SINGLE MENU/PRODUCT
  const {
    data: singleProductsData,
    isLoading: isSingleProductsDataLoading,
    isSuccess,
  } = useQuery<SingleProductData>([`single-products-${slug}`], () =>
    getSingleProducts(`${slug}`),
  );

  const toggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  // THIS USEEFFECT IS TO FREEZE SCROLLING OF THE BACKGROUND WHILE THE MODAL IS OPEN
  useEffect(() => {
    if (isModalOpen) {
      window.document.body.style.overflowY = "hidden";
    } else {
      window.document.body.style.overflowY = "scroll";
    }
  }, [isModalOpen]);

  const handleClick = () => {
    navigate(-1);

    toast.success("Item(s) Added to Cart");
  };

  return (
    <Layout title={` ${slug} `} description={`${slug}-order-page`}>
      <section className={styles.singleMenu}>
        {isSingleProductsDataLoading ? (
          <div className={styles.loader}>
            <Oval
              wrapperClass="mx-auto"
              height={50}
              width={50}
              strokeWidth={5}
            />
          </div>
        ) : (
          isSuccess && (
            <div className="flex flex-col items-start justify-center gap-8 my-8">
              <figure className={`container ${styles.singleMenuCard}`}>
                <img
                  className={styles.cardImage}
                  src={singleProductsData?.image[0]}
                  alt={singleProductsData.name}
                  loading="eager"
                />

                <figcaption className={`md:container ${styles.cardContent}`}>
                  <article className={styles.cardBody}>
                    <h4 className={styles.cardTitle}>
                      {singleProductsData.name}
                    </h4>
                    <p className={`${styles.cardCaption}`}>
                      <span
                        className={
                          singleProductsData?.discount > 0
                            ? "line-through pr-4 text-red-600"
                            : ""
                        }
                      >
                        {formatToNigerianNaira(singleProductsData?.price)}
                      </span>
                      {singleProductsData?.discount > 0 && (
                        <span>
                          {formatToNigerianNaira(singleProductsData?.discount)}
                        </span>
                      )}
                    </p>

                    <p className={styles.cardDescription}>
                      {singleProductsData.description}
                    </p>

                    <button
                      className={styles.orderNowBtn}
                      onClick={toggleModal}
                    >
                      Order Now
                    </button>
                  </article>
                </figcaption>
              </figure>

              {singleProductsData?.reviews.length > 0 && (
                <section className="container">
                  <div className="w-full flex items-center justify-between pt-5 my-10">
                    <h2 className="text-[#041D05] text-[20px] font-semibold leading-[1.1]">
                      Reviews
                    </h2>

                    <button className="text-[#339E35] text-[14px] font-medium leading-[1.1]">
                      View All Reviews
                    </button>
                  </div>

                  <div className="w-full flex flex-wrap items-center justify-between gap-5">
                    {singleProductsData.reviews.map((review: Reviews) => (
                      <div
                        className="w-[45%] py-5 px-6 flex flex-col items-start justify-center rounded-lg outline-transparent hover:outline hover:outline-1 hover:outline-[#fd9f2880] transition-all duration-300 ease-in"
                        key={review.id}
                      >
                        <div className="w-full flex items-center justify-between">
                          <h1 className="font-semibold text-[20px] text-[#041D05] leading-[1.6]">
                            {review?.user ? review?.user : "Anonymous"}
                          </h1>
                          <div className="flex items-center justify-end">
                            {review.rating && review.rating === 5 ? (
                              Array(Number(review.rating))
                                .fill(
                                  <TiStarFullOutline className="text-2xl text-[#339E35]" />,
                                )
                                .map((star: ReactNode, index: number) => (
                                  <Fragment key={index}>{star}</Fragment>
                                ))
                            ) : (
                              <>
                                {Array(Number(review.rating))
                                  .fill(
                                    <TiStarFullOutline className="text-2xl text-[#339E35]" />,
                                  )
                                  .map((star: ReactNode, index: number) => (
                                    <Fragment key={index}>{star}</Fragment>
                                  ))}
                                {review.rating < 5 &&
                                  Array(5 - Number(review.rating))
                                    .fill(
                                      <TiStarOutline className="text-2xl text-[#339E35]" />,
                                    )
                                    .map((star: ReactNode, index: number) => (
                                      <Fragment key={index}>{star}</Fragment>
                                    ))}
                              </>
                            )}
                          </div>
                        </div>

                        <p className="w-full pt-3 font-normal text-[14px] text-[#041D05] leading-[1.6] line-clamp-3">
                          {review.comment}
                        </p>
                      </div>
                    ))}
                  </div>
                </section>
              )}
            </div>
          )
        )}

        {isModalOpen && (
          <OrderPlusExtraModal
            toggleModal={toggleModal}
            slug={slug}
            handleClick={handleClick}
          />
        )}
      </section>
    </Layout>
  );
};

export default SingleMenu;
