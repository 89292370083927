import Button from "../../../common/Button";
import styles from "./SuperComboBanner.module.scss";
import SuperComboMeal from "../../../../assests/images/HomePage/super-combo-meal-plan.png";
// import SuperComboMeal from '../../../../assests/images/HomePage/super-combo-meal-plan__.webp'

const SuperComboBanner = () => {
  return (
    <section className={styles.superComboBanner}>
      <div className={`container ${styles.superComboContainer}`}>
        <article className={styles.superComboArticle}>
          <h2 className={styles.superComboArticleTitle}>
            Discover Fresh Flavours
          </h2>
          <p className={styles.superComboArticleText}>
            Feeling like a full meal, grab-and-go snack or a refreshing drink?
            We've got the freshest and healthiest flavours to keep you going all
            day. Order a So Fresh meal now, the #1 convenient way to get fresh,
            better-for-you food on demand.
          </p>
          <p className={styles.superComboArticleSubText}>
            Order now for delivery or pickup
          </p>
          {/* <p className={styles.superComboArticleText}>
            Thrill your taste buds, while you nourish your body, with our
            incredibly delicious SUPER COMBO OFFERS! Relish the best of fresh,
            Nutrient-rich and healthy Meals in one Offer!
          </p> */}
          {/* <p className={styles.superComboArticleSubText}>
            3 TIMES FRESHER! 3 TIMES HEALTHIER!
          </p> */}

          <div className={styles.btnContainer}>
            <Button
              linkHref="https://chowdeck.com/store/tag/so-fresh?page=tags"
              linkText="Order Now"
            />
            {/* <Button linkHref='/meal-plans' linkText='Order Now' /> */}
          </div>
        </article>

        <figure
          className={`${styles.superComboImageContainer} w-full flex items-center justify-end`}
        >
          <img
            className={styles.superComboImage}
            src={SuperComboMeal}
            alt="Super Combo Meal Plans"
            loading="eager"
          />
        </figure>
      </div>
    </section>
  );
};

export default SuperComboBanner;
