// REACT & DEFAULTS
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";

// COMPONENTS
import { getPlanTypes } from "../../lib/handlers/productHandler";

// UTILS & LIBs
import Layout from "../../components/layouts/Layout";
import MealPlansBanner from "../../components/layouts/MealPlans/MealPlansBanner";
import MealPlansHowSection from "../../components/layouts/MealPlans/MealPlansHowSection";
import MealPlansSection from "../../components/layouts/MealPlans/MealPlansSection";

// STYLES
import styles from "./MealPlans.module.scss";
import { useLocation } from "react-router-dom";
import { Oval } from "react-loader-spinner";

const MealPlan = () => {
  const location = useLocation();

  const { data: plansData, isLoading: isplansDataLoading } = useQuery<
    TMealPlansData[]
  >([`${location.pathname}`], () => getPlanTypes(`${location.pathname}`));

  const [planTypeState, setPlanTypeState] = useState({
    name: "",
    description: "",
    image: "",
  });

  useEffect(() => {
    setPlanTypeState({
      name: plansData ? plansData[0]?.name : "",
      description: plansData ? plansData[0]?.description : "",
      image: plansData ? plansData[0]?.image[0] : "",
    });
  }, [plansData]);

  return (
    <Layout title={"Meal Plans"} description={"Meal Plans Page"}>
      <main className={`container ${styles.mealPlans}`}>
        {isplansDataLoading ? (
          <div className={styles.modalLoader}>
            <Oval
              wrapperClass="mx-auto"
              height={50}
              width={50}
              strokeWidth={5}
            />
          </div>
        ) : (
          <>
            <MealPlansBanner plansData={planTypeState} />
            <MealPlansHowSection />
            <MealPlansSection plansData={plansData} />
          </>
        )}
      </main>
    </Layout>
  );
};

export default MealPlan;
