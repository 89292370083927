// REACT DEFAULTS
import { useState } from "react";
// import { useQuery } from '@tanstack/react-query'

// LIBs & UTILs
// import { getproducts } from '../../lib/handlers/productHandler'
import useProductContext from "../../hooks/useProducts";

// COMPONENTS & UTILS
import Layout from "../../components/layouts/Layout";

// STYLES
import styles from "./Menu.module.scss";
import Card from "../../components/common/Card";
import { Oval } from "react-loader-spinner";
import { sortFn } from "../../lib/utlis/helper";
import { useQuery } from "@tanstack/react-query";
import { getProductCategories } from "../../lib/handlers/productHandler";

const Menu = () => {
  // GET FAVOURITE PRODUCTS DATA TO DISPLAY ON CAROUSEL
  const { productsData, isProductsDataLoading } = useProductContext();
  const [selectedCategory, setSelectedCategory] = useState("all");

  const { data: productCategoriesData, isLoading: isProductCaegoryLoading } =
    useQuery(["product-categories"], () => getProductCategories(), {
      retry: 1,
    });
  // console.log('productsData: ', productsData)
  // console.log('productCategoriesData: ', productCategoriesData)

  function getProductsByCategory(category: string): TProductType[] {
    if (category === "all") {
      return productsData;
    } else {
      return (
        productsData &&
        (productsData
          ?.filter(
            (product) =>
              product.categories &&
              product.categories.find(
                (item: ICategory) => item.name === category,
              ),
          )
          .map((product) => {
            if (!product) return null; // Add a check to handle undefined values

            // const { id, name, image, price, slug, description, extras } =
            //   product
            return {
              ...product,
              // categories: product.categories,
            };
          })
          .filter(Boolean) as TProductType[])
      );
    }
  }
  // console.log('FILTER: ', getProductsByCategory('Fresh Salads'))

  const handleCategoryFilter = (category: string) => {
    setSelectedCategory(category);
  };

  return (
    <Layout title={" Menu "} description={"Menu Page"}>
      <main className={styles.menu}>
        <div className={`container ${styles.categoriesContainer}`}>
          {isProductCaegoryLoading ? (
            <div className={`{styles.loader}  my-32`}>
              <Oval
                wrapperClass="ml-2"
                height={30}
                width={30}
                strokeWidth={5}
              />
            </div>
          ) : (
            <div className={styles.productCategories}>
              <button
                onClick={() => handleCategoryFilter("all")}
                className={`${styles.category}  ${
                  selectedCategory === "all"
                    ? styles.categoryActive
                    : styles.categoryInActive
                }`}
              >
                All Categories
              </button>
              {productCategoriesData?.sort(sortFn)?.map((item: any) => (
                <button
                  onClick={() => handleCategoryFilter(item.name)}
                  className={`${styles.category} ${
                    selectedCategory === item.name
                      ? styles.categoryActive
                      : styles.categoryInActive
                  }`}
                  key={item.name}
                >
                  {item.name}
                </button>
              ))}
            </div>
          )}
        </div>
        <section className={`container ${styles.menuContainer}`}>
          {isProductsDataLoading ? (
            <div className={`{styles.loader}  my-32`}>
              <Oval
                wrapperClass="ml-2"
                height={50}
                width={50}
                strokeWidth={5}
              />
            </div>
          ) : (
            <>
              {getProductsByCategory(selectedCategory)?.length < 1 ? (
                <p className="w-full text-center px-[16px]">
                  NO PRODUCT IN THIS CATEGORY, PLEASE CHECK OTHER CATEGORIES.
                </p>
              ) : (
                <div className={styles.menuCards}>
                  {/* To sort products/menu in ORDER OF LATEST I used the util sortFn() */}
                  {getProductsByCategory(selectedCategory)
                    ?.sort(sortFn)
                    ?.map((menu: TProductType) => (
                      <Card
                        key={menu.id}
                        maxWidth={"100%"}
                        width={"300px"}
                        bigScreensImageUrl={menu.image}
                        smallScreensImageUrl={menu.image}
                        cardTitle={menu.name}
                        discount={menu.discount}
                        cardDescription={menu.description}
                        cardCaption={menu.price}
                        // itemLink={`/menu/${menu.slug}`}
                        // btnText={'Order Now'}
                      />
                    ))}
                </div>
              )}
            </>
          )}
        </section>
      </main>
    </Layout>
  );
};

export default Menu;
