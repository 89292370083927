// REACT & DEFAUKTS
import { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useQuery } from "@tanstack/react-query";

// COMPONENTS, UTILS& EXTERNAL LIBs
import Layout from "../../components/layouts/Layout";
import {
  getCompletedOrderHistory,
  getPendingOrderHistory,
} from "../../lib/handlers/orderHandler";

// STYLES
import styles from "./OrderHistory.module.scss";
import OrderHistoryCard from "../../components/common/OrderHistoryCard";
import OrderSuccessModal from "../../components/common/OrderSuccessModal";
import { Oval } from "react-loader-spinner";

const OrderHistory = () => {
  const [cookies] = useCookies<string>(["sofresh-token"]);
  let location = useLocation();
  let navigate = useNavigate();
  // console.log('location', location)

  // MODAL
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
    navigate("/order-history");
  }, [navigate]);

  // SET MODAL OPEN WHEN REDIRECTED FROM PAYMENT
  useEffect(() => {
    if (location.search.includes("?modal=true")) {
      setIsOpen(true);
    } else {
      handleCloseModal();
      document.body.style.overflow = "auto";
    }
  }, [handleCloseModal, location.search]);
  // END OF MODAL

  // SWITCH TABS
  const [switchTab, setSwitchTab] = useState<number>(2);
  const handleSwitchTabs = (index: number) => {
    setSwitchTab(index);
  };
  const getActiveClass = (index: number, className: string) =>
    switchTab === index ? className : styles.inActiveStatus;
  // END OF SWITCH TABS

  // GET PENDING ORDER HISTORY DATA
  const { data: pendingOrderHistory, isLoading: isPendingOrderHistoryLoading } =
    useQuery(
      // const { data: pendingOrderHistory } = useQuery(
      ["pending-order-history"],
      () => getPendingOrderHistory(cookies["sofresh-token"]),
    );
  // console.log('pendingOrderHistory: ', pendingOrderHistory)
  // END OF GET PENDING ORDER HISTORY DATA

  // GET COMPLTED ORDER HISTORY DATA
  const {
    data: completedOrderHistory,
    isLoading: isCompletedOrderHistoryLoading,
  } = useQuery(["completed-order-history"], () =>
    getCompletedOrderHistory(cookies["sofresh-token"]),
  );
  // console.log('completedOrderHistory: ', completedOrderHistory)
  // GET COMPLTED ORDER HISTORY DATA

  const DisplayPendingOrderHistory = () => {
    return (
      <>
        {pendingOrderHistory && pendingOrderHistory.length > 0 ? (
          pendingOrderHistory.map((history: TOrderHistoryCardData) => (
            <OrderHistoryCard
              key={history.reference}
              reference={history.reference}
              created_at={history.created_at}
              carts_id_length={history.carts_id.length}
              delivery_type={history.delivery_type}
              total_amount={history.total_amount}
            />
          ))
        ) : (
          <p className={styles.emptyOrderHistory}>No Pending Order</p>
        )}
      </>
    );
  };

  const DisplayCompletedOrderHistory = () => {
    return (
      <>
        {completedOrderHistory && completedOrderHistory.length > 0 ? (
          completedOrderHistory.map((history: TOrderHistoryCardData) => (
            <OrderHistoryCard
              key={history.reference}
              reference={history.reference}
              created_at={history.created_at}
              carts_id_length={history.carts_id.length}
              delivery_type={history.delivery_type}
              total_amount={
                Number(history.total_amount) + Number(history.delivery_fee)
              }
            />
          ))
        ) : (
          <p className={styles.emptyOrderHistory}>No Completed Order History</p>
        )}
      </>
    );
  };

  const DisplayOrderHistory = () => {
    return (
      <>
        {switchTab === 1 ? (
          <DisplayPendingOrderHistory />
        ) : (
          <DisplayCompletedOrderHistory />
        )}
      </>
    );
  };

  return (
    <Layout title={" Order History "} description={"Order History Page"}>
      <main className={styles.orderHistory}>
        <section className={`container ${styles.orderHistoryContainer}`}>
          <h1 className={styles.orderTitle}>Orders</h1>

          <article className={styles.orderStatusDetailsContainer}>
            <div className={styles.orderStatusBtnContainer}>
              <button
                onClick={() => handleSwitchTabs(1)}
                className={`${styles.orderStatusBtn} ${getActiveClass(
                  1,
                  styles.activeStatus,
                )}`}
                type="button"
              >
                Ongoing Orders
              </button>
              <button
                onClick={() => handleSwitchTabs(2)}
                className={`${styles.orderStatusBtn} ${getActiveClass(
                  2,
                  styles.activeStatus,
                )}`}
                type="button"
              >
                Completed Orders
              </button>
            </div>

            {isCompletedOrderHistoryLoading || isPendingOrderHistoryLoading ? (
              <div className={styles.modalLoader}>
                <Oval
                  wrapperClass="mx-auto"
                  height={50}
                  width={50}
                  strokeWidth={5}
                />
              </div>
            ) : (
              <DisplayOrderHistory />
            )}
          </article>
        </section>

        {isOpen && <OrderSuccessModal isOpen onClose={handleCloseModal} />}
      </main>
    </Layout>
  );
};

export default OrderHistory;
