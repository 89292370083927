// REACT & DEFAULTS
import { useCallback, useState, useContext, Fragment, ReactNode } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

// COMPONENTS, LIBs & UTILs
import Layout from "../../components/layouts/Layout";
import {
  formatToNigerianNaira,
  removeSubstringAfterLastSlash,
} from "../../lib/utlis/helper";
import { getSinglePlanType } from "../../lib/handlers/orderHandler";

// STYLES
import styles from "./SingleMealPlan.module.scss";
import SinglePlanTypeModal from "../../components/common/singlePlanTypeModal";
import { Oval } from "react-loader-spinner";
import { OrderContext } from "../../context/OrderContext";
import { TiStarFullOutline, TiStarOutline } from "react-icons/ti";

type Reviews = {
  id: string | number;
  user: string;
  comment: string;
  rating: number;
  is_active: boolean;
};

const SingleMealPlan = () => {
  // let navigate = useNavigate()
  const location = useLocation();
  const params = useParams();
  const { cartState, dispatch } = useContext(OrderContext);

  const { data: planTypeData, isLoading: isplanTypeDataLoading } = useQuery(
    [`planType-${params.slug}`],
    () =>
      getSinglePlanType({
        planType: `${removeSubstringAfterLastSlash(location)}`,
        slug: `${params.slug}`,
      }),
  );
  // console.log("planTypeData[0]?.plan_types: ", planTypeData);

  // MODAL
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleIsModalOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <Layout title={"Meal Plans"} description={"Meal Plans Page"}>
      <main className={`container ${styles.mealPlans}`}>
        {isplanTypeDataLoading ? (
          <div className={styles.modalLoader}>
            <Oval
              wrapperClass="mx-auto"
              height={50}
              width={50}
              strokeWidth={5}
            />
          </div>
        ) : (
          <section className={styles.singleMealPlan}>
            {planTypeData &&
              planTypeData[0]?.plan_types?.map((item: any) => (
                <>
                  <figure key={item.id} className={styles.singleMealPlanCard}>
                    <img
                      className={styles.singleMealPlanCardImage}
                      src={item.image[0]}
                      alt="single plan"
                    />

                    <figcaption className={styles.singleMealPlanCardContent}>
                      <h2 className={styles.singleMealPlanCardTitle}>
                        {item.name}
                      </h2>
                      <p className={styles.singleMealPlanCardText}>
                        {item.description}
                      </p>

                      <p className={styles.singleMealPlanCardDuration}>
                        <span
                          className={styles.singleMealPlanCardDurationTitle}
                        >
                          Duration of Plan
                        </span>
                        <span
                          className={styles.singleMealPlanCardDurationContent}
                        >
                          {item.duration[0].days}{" "}
                          {`${
                            item.duration[1]?.days
                              ? ` - ` + item.duration[1]?.days
                              : " "
                          } Day(s)`}
                        </span>
                      </p>

                      <p className={styles.singleMealPlanCardPrice}>
                        <span className={styles.singleMealPlanCardPriceTitle}>
                          Cost of Plan
                        </span>
                        <span className={styles.singleMealPlanCardPriceContent}>
                          {`${
                            item.duration[0]?.discount_price
                              ? formatToNigerianNaira(
                                  item.duration[0]?.discount_price,
                                )
                              : formatToNigerianNaira(item.duration[0]?.price)
                          } ${
                            item.duration[1]
                              ? `- ${
                                  item.duration[1]?.discount_price
                                    ? formatToNigerianNaira(
                                        item.duration[1]?.discount_price,
                                      )
                                    : formatToNigerianNaira(
                                        item.duration[1]?.price,
                                      )
                                }`
                              : ""
                          }`}
                        </span>
                      </p>

                      <div className={styles.singleMealPlanCardBtnContainer}>
                        <button
                          className={styles.singleMealPlanCardBtn}
                          onClick={() =>
                            cartState.plans.find(
                              (cart) => cart.slug === item.slug,
                            )
                              ? dispatch({
                                  type: "REMOVE_PLAN_FROM_CART",
                                  payload: item.slug,
                                })
                              : handleIsModalOpen()
                          }
                        >
                          {`${
                            cartState.plans.find(
                              (cart) => cart.slug === item.slug,
                            )
                              ? "Remove Meal Plan From Cart"
                              : "Add Meal Plan To Cart"
                          } `}
                        </button>
                      </div>
                    </figcaption>
                  </figure>

                  {item?.reviews.length > 0 && (
                    <section className="my-10 pt-5">
                      <div className="w-full flex items-center justify-between pt-5 my-10">
                        <h2 className="text-[#041D05] text-[20px] font-semibold leading-[1.1]">
                          Reviews
                        </h2>

                        <button className="text-[#339E35] text-[14px] font-medium leading-[1.1]">
                          View All Reviews
                        </button>
                      </div>

                      <div className="w-full pt-3 my-10 flex flex-wrap items-center justify-between gap-5">
                        {item.reviews.map((review: Reviews) => (
                          <div
                            className="w-[45%] py-5 px-6 flex flex-col items-start justify-center rounded-lg outline-transparent hover:outline hover:outline-1 hover:outline-[#fd9f2880] transition-all duration-300 ease-in"
                            key={review.id}
                          >
                            <div className="w-full flex items-center justify-between">
                              <h1 className="font-semibold text-[20px] text-[#041D05] leading-[1.6]">
                                {review?.user ? review?.user : "Anonymous"}
                              </h1>
                              <div className="flex items-center justify-end">
                                {review.rating && review.rating === 5 ? (
                                  Array(Number(review.rating))
                                    .fill(
                                      <TiStarFullOutline className="text-2xl text-[#339E35]" />,
                                    )
                                    .map((star: ReactNode, index: number) => (
                                      <Fragment key={index}>{star}</Fragment>
                                    ))
                                ) : (
                                  <>
                                    {Array(Number(review.rating))
                                      .fill(
                                        <TiStarFullOutline className="text-2xl text-[#339E35]" />,
                                      )
                                      .map((star: ReactNode, index: number) => (
                                        <Fragment key={index}>{star}</Fragment>
                                      ))}
                                    {review.rating < 5 &&
                                      Array(5 - Number(review.rating))
                                        .fill(
                                          <TiStarOutline className="text-2xl text-[#339E35]" />,
                                        )
                                        .map(
                                          (star: ReactNode, index: number) => (
                                            <Fragment key={index}>
                                              {star}
                                            </Fragment>
                                          ),
                                        )}
                                  </>
                                )}
                              </div>
                            </div>

                            <div>
                              <p className="w-full font-normal text-[14px] text-[#041D05] pt-3 leading-[1.6] line-clamp-3">
                                {review.comment}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </section>
                  )}
                </>
              ))}

            {isModalOpen && (
              <SinglePlanTypeModal
                isModalOpen={isModalOpen}
                onClose={handleCloseModal}
                planTypeData={planTypeData}
                isplanTypeDataLoading={isplanTypeDataLoading}
                key={planTypeData[0].slug}
              />
            )}
          </section>
        )}
      </main>
    </Layout>
  );
};

export default SingleMealPlan;
