// COMPONENTS, IMAGES & ICONS
import Button from "../../../common/Button";
import AboutImage from "../../../../assests/images/HomePage/aboutBannerImage.webp";

// STYLES
import styles from "./HomeAboutSection.module.scss";

const HomeAboutSection = () => {
  return (
    <section className={styles.homeAboutSection}>
      <div className={`container ${styles.homeAboutSection}`}>
        <article className={`${styles.aboutSectionCaptionContainer}`}>
          <div className={styles.aboutSectionCaption}>
            <h2 className={styles.aboutSectionCaptionTitle}>
              The So Fresh Story
            </h2>
            <p className={styles.aboutSectionCaptionText}>
              SoFresh is Nigeria&apos;s pioneer and number one healthy food
              chain, providing fresh, delicious, nutrient-rich healthy meals and
              committed to promoting a healthy lifestyle. FRESH IS BEST and that
              is how we win every day
              <span className={styles.displaySmNone}>
                , selling a wide range of fresh salads, juices, smoothies,
                parfait, fiesta wraps, sandwich and other quick on the go
                healthy meals
              </span>
              .
            </p>
            <Button linkHref="/about" linkText="Read More" lightBtn />
          </div>
        </article>

        <div className={styles.aboutSectionImageContainer}>
          <img
            className={styles.aboutSectionImage}
            src={AboutImage}
            alt="about us section"
            loading="eager"
          />
        </div>
      </div>
    </section>
  );
};

export default HomeAboutSection;
