// REACT & DEFAULTS
// import { useState } from 'react'

// COMPONENTS, UTILS& EXTERNAL LIBs
import Layout from "../../components/layouts/Layout";
import HeroBanner from "../../components/layouts/Home/HeroBanner";
import HomeCarousel from "../../components/layouts/Home/HomeCarousel";
import SuperComboBanner from "../../components/layouts/Home/SuperComboBanner";
// import CustomersFavourite from '../../components/layouts/Home/CustomersFavourite'
import HomeBlogCards from "../../components/layouts/Home/HomeBlogCards";
import HomeAboutSection from "../../components/layouts/Home/HomeAboutSection";
import HomeSubscription from "../../components/layouts/Home/HomeSubscription";
// import SelectStoreModal from '../../components/layouts/Home/SelectStoreModal'

// STYLES
import styles from "./Home.module.scss";

const Home = () => {
  // const isStoreVailable = localStorage.getItem('soFreshStore')

  // const [isSelectStoreModalOpen, setIsSelectStoreModalOpen] = useState(
  //   isStoreVailable ? false : true
  // )
  // const handleCloseSelectStoreModal = () => {
  //   setIsSelectStoreModalOpen(false)
  // }

  return (
    <Layout title={" Home "} description={"Home Page"}>
      <main className={styles.home}>
        <HeroBanner />
        <HomeCarousel />
        <SuperComboBanner />
        {/* <CustomersFavourite /> */}
        <HomeBlogCards />
        <HomeAboutSection />
        <HomeSubscription />

        {/* {isSelectStoreModalOpen && (
          <SelectStoreModal
            handleCloseSelectStoreModal={handleCloseSelectStoreModal}
            isSelectStoreModalOpen={isSelectStoreModalOpen}
          />
        )} */}
      </main>
    </Layout>
  );
};

export default Home;
